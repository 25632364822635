import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "max-w-container padding px-container text-amff-black-2024" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")
  const _component_PageContent = _resolveComponent("PageContent")
  const _component_PreviousEditions = _resolveComponent("PreviousEditions")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_PageTitle, { class: "py-10" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("header.history")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_PageContent, null, {
        default: _withCtx(() => [
          (_ctx.currentLang === 'cat')
            ? (_openBlock(), _createBlock("p", _hoisted_2, " L'Atlàntida Film Fest va néixer fa 12 anys com el primer festival de cinema online al nostre país i ho va fer amb una missió: donar a conèixer al públic les millores pel·lícules internacionals que no trobaven lloc a les sales. El primer objectiu va ser la visibilitat, el segon: donar prestigi a la finestra d'Internet i posteriorment va arribar Mallorca. Avui, l'Atlàntida és el festival híbrid més important del món que, en la seva 11a edició va superar els 750.000 espectadors en la seva doble seu: una setmana a Mallorca i un mes a Filmin. A Mallorca han vingut figures internacionals tan rellevants com Judi Dench, Ken Loach, Vanessa Redgrave, Stephen Frears, Roland Joffé, Barbara Broccoli, Boris Pahor, Barbet Schroeder així com els directors nacionals més rellevants. L'Atlàntida ha estat escollit Millor Festival de les Illes Balears (Observatori de la Cultura). "))
            : (_openBlock(), _createBlock("p", _hoisted_3, " Atlàntida Film Fest nació hace 12 años como el primer festival de cine online en nuestro país y lo hizo con una misión: dar a conocer al público las mejores películas internacionales que no encontraban lugar en las salas. El primer objetivo fue la visibilidad, el segundo: dar prestigio a la ventana de Internet y posteriormente llegó Mallorca. Hoy, Atlàntida es el festival híbrido más importante del mundo que, en su 11ª edición superó los 750.000 espectadores en su doble sede: una semana en Mallorca y un mes en Filmin. En Mallorca han venido figuras internacionales tan relevantes como Judi Dench, Ken Loach, Vanessa Redgrave, Stephen Frears, Roland Joffé, Barbara Broccoli, Boris Pahor, Barbet Schroeder así como los directores nacionales más relevantes. Atlàntida ha sido elegido Mejor Festival de las Islas Baleares (Observatorio de la Cultura). "))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_PreviousEditions)
  ], 64))
}