export default [
  {
    title: {
      es: "Dirección",
      cat: "Direcció",
    },
    people: [
      {
        name: "Jaume Ripoll",
        position: {
          es: "Dirección artística",
          cat: "Direcció artística",
        },
      },
      {
        name: "Juan Carlos Tous",
        position: {
          es: "Dirección ejecutiva",
          cat: "Direcció executiva",
        },
      },
      {
        name: "Joan Sala",
        position: {
          es: "Subdirección / programación",
          cat: "Subdirecció / programació",
        },
      },
      {
        name: "Cristina Gómez",
        position: {
          es: "Subdirección / producción",
          cat: "Subdirecció / producció",
        },
        email: "cristina@atlantidafilmfest.es",
        phone: "+34 661 620 807",
      },
    ],
  },
  {
    title: {
      es: "Programación",
      cat: "Programació",
    },
    people: [
      {
        name: "Elodie Mellado",
        position: {
          es: "Coordinadora de programación / Nins",
          cat: "Coordinadora de programació / Nins",
        },
      },
      {
        name: "Manel Domínguez",
        position: {
          es: "Programación",
          cat: "Programació",
        },
      },
    ],
  },
  {
    title: {
      es: "Prensa",
      cat: "Premsa",
    },
    people: [
      {
        name: "Núria Costa",
        email: "nuria@atlantidafilmfest.es",
        phone: "+34 696 179 881",
      },
      {
        name: "Maite Robles",
      },
      {
        name: "Anna Prats",
      },
    ],
  },
  {
    title: {
      es: "Comunicación y Marketing",
      cat: "Communicació i Màrqueting",
    },
    people: [
      {
        name: "Pilar Toro",
        position: {
          es: "Directora de Marketing",
          cat: "Directora de Màrqueting",
        },
      },
      {
        name: "Zaida Carmona",
        position: {
          es: "Brand Lead",
          cat: "Brand Lead",
        },
      },
      {
        name: "Eric Monteagudo",
        position: {
          es: "Creative Lead",
          cat: "Creative Lead",
        },
      },
      {
        name: "Xesca Sastre",
        position: {
          es: "Social Media",
          cat: "Social Media",
        },
        email: "xesca@atlantidafilmfest.es",
        phone: "+34 671 968 206 ",
      },
      {
        name: "María Navarro",
        position: {
          es: "Social Media",
          cat: "Social Media",
        },
      },
      {
        name: "Alberto Van den Eyde",
        position: {
          es: "Video Editor",
          cat: "Video Editor",
        },
      },
    ],
  },
  {
    title: {
      es: "Diseño",
      cat: "Disseny",
    },
    people: [
      {
        name: "Iván J",
        position: {
          es: "Diseño",
          cat: "Disseny",
        },
      },
      {
        name: "Aina Cuatrecasas",
        position: {
          es: "Diseño",
          cat: "Disseny",
        },
      },
      {
        name: "Alex Espinosa",
        position: {
          es: "Editor de vídeo",
          cat: "Editor de vídeo",
        },
      },
    ],
  },
  {
    title: {
      es: "Invitados",
      cat: "Convidats",
    },
    people: [
      {
        name: "Estrella Barbadillo",
        position: {
          es: "Vuelos, alojamiento y traslados",
          cat: "Vols, allotjament i trasllats",
        },
        email: "estrella@atlantidafilmfest.es",
        phone: "+34 675 995 603",
      },
      {
        name: "Juan Pablo Oviedo",
        position: {
          es: "Agendas y gestión de invitados",
          cat: "Agendes i gestió de convidats",
        },
      },
      {
        name: "Guillermo Escuder",
        position: {
          es: "Agendas y gestión de invitados",
          cat: "Agendes i gestió de convidats",
        },
        email: "guille@atlandidafilmfest.es",
        phone: "+34 615 514 940",
      },
      {
        name: "Tania Cànaves",
        position: {
          es: "Agendas y gestión de invitados",
          cat: "Agendes i gestió de convidats",
        },
        email: "tania@atlandidafilmfest.es",
      },
    ],
  },
  {
    title: {
      es: "Mallorca Talents Lab",
      cat: "Mallorca Talents Lab",
    },
    people: [
      {
        name: "Virginia Galán",
        email: "talent@atlantidafilmfest.es",
        phone: "+34 636 739 214",
      },
    ],
  },
  {
    title: {
      es: "Patrocinios",
      cat: "Patrocinis",
    },
    people: [
      {
        name: "Lara Macip",
        position: {
          es: "Gestión de patocinios",
          cat: "Gestió de patrocinis",
        },
        email: "sponsors@atlantidafilmfest.es",
        phone: "+34 646 526 999",
      },
    ],
  },
  {
    title: {
      es: "Producción",
      cat: "Producció",
    },
    people: [
      {
        name: "Claudia Calvo",
        position: {
          es: "Gestión de sedes",
          cat: "Gestió de seus",
        },
        email: "claudia@atlantidafilmfest.es",
        phone: "+34 645 012 043",
      },
      {
        name: "Joan Porcel",
        position: {
          es: "Producción técnica / gestión copias",
          cat: "Producció tècnica / gestió còpies",
        },
        email: "joanporcel@atlantidafilmfest.es",
        phone: "+34 650 025 899",
      },
      {
        name: "Valentina Gómez",
        position: {
          es: "Gestión de público",
          cat: "Gestió de públic",
        },
        email: "tickets@atlantidafilmfest.es",
      },
      {
        name: "Andrea Cruz",
        position: {
          es: "Producción musical",
          cat: "Producció musical",
        },
        email: "andrea@atlantidafilmfest.es",
        phone: "+34 633 68 53 69",
      },
      {
        name: "Cécile Parra",
        position: {
          es: "Controller",
          cat: "Controller",
        },
        email: "cecile@atlantidafilmfest.es",
      },
      {
        name: "Álvaro Augusto",
        position: {
          es: "Talks",
          cat: "Talks",
        },
        email: "alvaro@atlantidafilmfest.es",
      },
    ],
  },
  {
    title: {
      es: "Administración",
      cat: "Administració",
    },
    people: [
      {
        name: "Pilar Heredia",
        position: {
          es: "Directora administrativa",
          cat: "Directora administrativa",
        },
      },
      {
        name: "Elna Roigé",
        position: {
          es: "Rights Manager",
          cat: "Rights Manager",
        },
      },
      {
        name: "Anna Soler",
        position: {
          es: "Royalties Manager",
          cat: "Royalties Manager",
        },
      },
    ],
  },
  {
    title: {
      es: "Talent",
      cat: "Talent",
    },
    people: [
      {
        name: "Cristina Jiménez",
        position: {
          es: "Head of Talent",
          cat: "Head of Talent",
        },
      },
      {
        name: "Andrea Adalid",
        position: {
          es: "Talent Coordinator",
          cat: "Talent Coordinator",
        },
      },
    ],
  },
  {
    title: {
      es: "Estudiantes en prácticas",
      cat: "Estudiants en practiques",
    },
    people: [
      {
        name: "Clara Calvo",
        email: "clara@atlantidafilmfest.es",
      },
      {
        name: "Andrián Sampere",
        email: "adrian@atlantidafilmfest.es",
      },
    ],
  },
  {
    title: {
      es: "Web y festival online Filmin",
      cat: "Web i festival online Filmin",
    },
    people: [
      {
        name: "Julia Slipenchuk",
        position: {
          es: "Diseño de producto",
          cat: "Disenny de producte",
        },
      },
      {
        name: "Alex García",
        position: {
          es: "Backend",
          cat: "Backend",
        },
      },
      {
        name: "Bruno Barros",
        position: {
          es: "Backend",
          cat: "Backend",
        },
      },
      {
        name: "Manel Puicerver",
        position: {
          es: "Backend",
          cat: "Backend",
        },
      },
      {
        name: "Eduard Terradas",
        position: {
          es: "Dirección técnica",
          cat: "Direcció tècnica",
        },
      },
      {
        name: "Irene Llona",
        position: {
          es: "Atención al cliente",
          cat: "Atenció al client",
        },
      },
      {
        name: "Andrea Rodríguez",
        position: {
          es: "Vídeo / codificación",
          cat: "Video / codificació",
        },
      },
      {
        name: "Fran Valero",
        position: {
          es: "Vídeo / codificación",
          cat: "Video / codificació",
        },
      },
      {
        name: "Álvaro Espínola",
        position: {
          es: "Vídeo / codificación",
          cat: "Video / codificació",
        },
      },
    ],
  },
  {
    title: {
      es: "Materiales, traducción y copias Filmin",
      cat: "Materials, traducció i còpies",
    },
    people: [
      {
        name: "Isa Sequeros",
        position: {
          es: "Gestión de materiales y traducciones",
          cat: "Gestió de materials y traduccions",
        },
      },
      {
        name: "Alicia Garrido",
        position: {
          es: "Gestión de materiales técnicos",
          cat: "Gestió de materials tècnics",
        },
      },
      {
        name: "Martina Alzor",
        position: {
          es: "Ayudante de traducción",
          cat: "Ajudant de traducció",
        },
      },
    ],
  },
];
