<template>
  <div class="text-amff-black-2024 max-w-container padding px-container">
    <PageTitle class="py-10 font-display" style="font-weight: 400"
      >{{ $t("header.team") }}
    </PageTitle>
    <div
      class="flex-row md:flex flex-wrap pt-12 border-b border-black"
      :key="'team-section-' + index"
      v-for="(teamSection, index) in teamSections"
    >
      <div class="w-1/4 font-bold text-xl pr-8">
        {{ teamSection.title[currentLang] }}
      </div>
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8 pb-10">
        <div
          :key="'team-person-' + index"
          class="w-48 h-22"
          v-for="(person, index) in teamSection.people"
        >
          <div class="text-xl">{{ person.name }}</div>
          <div v-if="person.position" class="text-sm">
            {{ person.position[currentLang] }}
          </div>
          <a
            v-if="person.email"
            :href="`mailto: ${person.email}`"
            class="text-sm underline"
            >{{ person.email }}</a
          >
          <div v-if="person.phone" class="text-sm">M. {{ person.phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import PageTitle from "@/components/PageTitle.vue";
import teamSections from "@/data/team";
import { useRouteWithLang } from "@/composables/useRouteWithLang";

export default defineComponent({
  components: {
    PageTitle,
  },
  setup() {
    const { currentLang } = useRouteWithLang();
    return {
      teamSections,
      currentLang,
    };
  },
});
</script>
