
import { defineComponent } from "vue";

const previousEditions = [
  2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
];

export default defineComponent({
  setup() {
    return {
      previousEditions,
      baseURL: process.env.BASE_URL,
    };
  },
});
