
import { defineComponent } from "vue";
import PageContent from "@/components/PageContent.vue";
import PageTitle from "@/components/PageTitle.vue";
import PreviousEditions from "@/components/PreviousEditions.vue";
import { useRouteWithLang } from "@/composables/useRouteWithLang";

export default defineComponent({
  components: {
    PageContent,
    PageTitle,
    PreviousEditions,
  },
  setup() {
    const { currentLang } = useRouteWithLang();
    return {
      currentLang,
    };
  },
});
