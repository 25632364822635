<template>
  <div class="PageContent text-base lg:text-xl font-thin">
    <slot />
  </div>
</template>

<style>
/* default styles for content */
.PageContent * + * {
  @apply mt-4 lg:mt-8;
}
.PageContent a {
  @apply text-amff-teal;
}

.PageContent ul {
  padding-left: 1rem;
  list-style: initial;
  margin-bottom: 3rem;
}
</style>
